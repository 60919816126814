export const VmStatusType = {
  UNKNOWN: 'UNKNOWN',
  TO_BE_PROVISIONED: 'TO_BE_PROVISIONED',
  PLANNING: 'PLANNING',
  PLANNING_COMPLETED: 'PLANNING_COMPLETED',
  PROVISIONING: 'PROVISIONING',
  PROVISIONING_COMPLETED: 'PROVISIONING_COMPLETED',
  PROVISIONING_FAILED: 'PROVISIONING_FAILED',
  INITIALIZING: 'INITIALIZING',
  RUNNING: 'RUNNING',
  STOPPED: 'STOPPED',
  SHUTDOWN: 'SHUTDOWN',
  TO_BE_DESTROYED: 'TO_BE_DESTROYED',
  DESTROYING: 'DESTROYING',
  DESTROYED: 'DESTROYED',
}

export type VmStatusType = typeof VmStatusType[keyof typeof VmStatusType]
