import { Icon } from '@chakra-ui/react'

const SvgEducloud = () => (
  <Icon viewBox='0 0 169 161' boxSize={5}>
    <path
      fill='currentColor'
      d="M116.5 102h-66l33-59zM40 110v48L0 87.5zM40 99.5 0 75 38.5 6 78 35zM83.5 27.5 45.5 0H121zM128.5 6l-39 29 37 64.5 42-24zM128.5 158v-48l40-23zM120 161v-49.5H47.5V161z"
    />
  </Icon>
)
export default SvgEducloud

