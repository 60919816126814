import { FiGrid } from 'react-icons/fi'
import { AtSignIcon, EditIcon } from '@chakra-ui/icons'
import { SidebarItem } from '../types/SIdebarItem.ts'
import { GoProjectTemplate } from 'react-icons/go'
import { BsClouds } from 'react-icons/bs'
import { TfiHelpAlt } from 'react-icons/tfi'

export const APP_NAME = import.meta.env.VITE_APP_NAME
export const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT
export const SOCKET_ENDPOINT = import.meta.env.VITE_SOCKET_ENDPOINT
export const AUTH_URL = import.meta.env.VITE_AUTH_URL
export const AUTH_CLIENT_ID = import.meta.env.VITE_CLIENT_ID
export const AUTH_REDIRECT_URL = import.meta.env.VITE_REDIRECT_URL
export const SENTRY_DSN= import.meta.env.VITE_SENTRY_DSN
export const AUTH_RESPONSE_TYPE = 'code'
export const AUTH_OPEN_ID_SCOPES = 'openid profile email projects groups'

export const VM_NAME_VALIDATION_REGEX = /^[A-Za-z0-9\-._]+$/ // alphanumeric, underscore, hyphen, dot

export const FILE_NAME_VALIDATION_REGEX = /^[a-zA-Z0-9_-]{2,}(\.[a-zA-Z0-9]+)?$/ // 2 or more characters, alphanumeric, underscore, hyphen, dot, optional extension

export const ALLOWED_IP_RANGES = [
  {name: 'UiO Network', ipRange: '129.240.0.0/16'},
  {name: 'UiO Eduroam', ipRange: '193.157.0.0/16'},
  {name: 'UiB Network', ipRange: '129.177.0.0/16'},
]

export const DEFAULT_SIDEBAR_TOP_ITEMS: SidebarItem[] = [
  {
    type: 'link',
    path: '/dashboard',
    matches: '/dashboard',
    text: 'Dashboard',
    icon: FiGrid
  },
  {
    type: 'link',
    path: '/create',
    matches: '/create',
    text: 'Create',
    icon: EditIcon
  },
  {
    type: 'link',
    path: '/profile',
    matches: '/profile',
    text: 'Profile',
    icon: AtSignIcon
  },
]

export const DEFAULT_SIDEBAR_BOTTOM_ITEMS: SidebarItem[] = [
  {
    type: 'link',
    path: '/help',
    matches: '/help',
    text: 'Help',
    icon: TfiHelpAlt
  },
]

export const ADMIN_SIDEBAR_ITEMS: SidebarItem[] = [
  {
    type: 'link',
    path: 'admin/providers',
    matches: '/providers',
    text: 'Providers',
    icon: BsClouds,
  },
  {
    type: 'link',
    path: 'admin/vm-templates',
    matches: '/vm-templates',
    text: 'VM Templates',
    icon: GoProjectTemplate,
  },
]
